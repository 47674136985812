import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";


const Master = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);


    return (

        <Layout pageTitle="" className="white-version">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />

        <div>
        <main className="main-page-wrapper">
                <div className="rn-slider-area">
                    <div className="container">
                        <div className="row row--30 pt--100 pt_sm--50">
                            <div className="col-lg-6">
                                <div className="d-flex flex-wrap align-content-start h-100">
                                    <Sticky
                                        className="sticky-top-slider"
                                        top="200px"
                                    >
                                        <div className="banner-details-wrapper-sticky slide">
                                            <div className="thumbnail">
                                                <img
                                                    src={
                                                        "../static/anuj-p-1.png"
                                                    }
                                                />

                                                {/*
                        <img src={"Anuj.png"} />
                        <img src={"./logo.jpeg"} />*/}
                                            </div>

                                            <div className="banner-title-area pt--30">
                                                <h1
                                                    className="title"
                                                    style={{ color: "#000" }}
                                                >
                                                    Hi, I’m <span>Anuj</span>
                                                    <br />
                                                    {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                </h1>
                                                <p className="disc">
                                                    For over 20 years, I’ve been
                                                    the go-to host of many top
                                                    Indian brands and
                                                    high-profile individuals for
                                                    formal & social events such
                                                    as corporate celebrations,
                                                    product launches, press
                                                    meets, weddings, concerts,
                                                    award nights, conventions &
                                                    conferences, and many more. 
                                                </p>
                                            </div>
                                            <div className="button-group text-center text-md-left pt--60 pt_md--40 pt_sm--40">
                                                <a
                                                    className="rn-btn"
                                                    href="#contacts"
                                                >
                                                    <span>
                                                        <span>
                                                            Download My CV
                                                        </span>
                                                    </span>
                                                </a>
                                                <a
                                                    className="rn-btn"
                                                    href="#contacts"
                                                >
                                                    <span>
                                                        <span>Contact</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </Sticky>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/*
                <div className="sticky-home-wrapper">
                  <div className="rn-about-area section-height">
                    <div className="inner">
                      <h5 className="title">About Me</h5>
                      <p className="about-disc">Hi I am a Front-End developer &amp; I can build your website as you want. I can customize plugin &amp; wordpress theme</p>
                    </div>
                  </div>
                </div>
                */}

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                MASTER OF CEREMONIES
                                            </h5>

                                            <p className="about-disc">
                                                Don’t bore your guests at your
                                                next corporate event or social
                                                dos by handling the mic to a
                                                non-professional.
                                            </p>
                                            <p className="about-disc">
                                                As an experienced master of
                                                ceremony who has worked with
                                                hundreds of clients & event
                                                planners on over a thousand
                                                events, I have the knowledge and
                                                charisma to connect with your
                                                audience, ensuring they enjoy
                                                every bit of the time spent in
                                                your event.
                                            </p>
                                            <p className="about-disc">
                                                Having mastered the art of
                                                audience psychology, I know how
                                                to engage with your audience
                                                using the right words that
                                                resonate with them, keeping them
                                                engaged and glued to your
                                                ceremony.
                                            </p>
                                            <p className="about-disc">
                                                For over two decades across
                                                India & the world, I’ve hosted
                                                top events, from corporate to
                                                social, weddings to award
                                                nights, and protocol events
                                                featuring Heads of State and
                                                some of the biggest names in the
                                                world of business,
                                                entertainment, and sports.
                                            </p>
                                            <p className="about-disc">
                                                I will keep your event exciting
                                                and moving at an expected pace.
                                            </p>

                                            <p
                                                style={{
                                                    color: "#ff0000",
                                                    margin: "10px 0px",
                                                }}
                                            >
                                                My Masters of Ceremonies
                                                Portfolio
                                            </p>

                                            <p className="about-disc">
                                                <ul>
                                                    <li>
                                                        A few notable events I
                                                        anchored for big brands:
                                                    </li>
                                                    <li>
                                                        Times food & nightlife
                                                        awards for 10 years, in
                                                        10 cities across India. 
                                                    </li>
                                                    <li>
                                                        Sony Entertainment
                                                        Television Network
                                                        events (including
                                                        launches of KBC, Scam
                                                        1992, Dus Ka Dum, and
                                                        many more)
                                                    </li>
                                                    <li>
                                                        Brands & organisations
                                                        I’ve worked with:
                                                    </li>
                                                    <li>
                                                        Microsoft, Apple, IIFA,
                                                        IPL, AFC, Challenge Cup,
                                                        Asian Paints, Mumbai
                                                        Mirror, Lodha, Reliance,
                                                        ICICI, Wipro,
                                                        Dr.Reddy’s, TrueNorth,
                                                        CEAT, P&G, HP, and many
                                                        more. 
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Photos of Events
                                            </h5>

                                            <div className="flex-50-m">
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>

                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Want to hear me speak? Check
                                                these videos
                                            </h5>

                                            <div className="flex-50-m">
                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/aBnbjt3Z8_w"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>

                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/6CHPapgCzJI"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/KBLQFFQw8q8"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Brand Logos
                                            </h5>

                                            <div className="flex-50-m">
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>

                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="flex-50-i">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Contact Me
                                            </h5>

                                            <p className="about-disc">
                                                Let’s connect on your event,
                                                either social or corporate. I’m
                                                available for freelance work as
                                                a master of ceremony, singer,
                                                actor, voice artist or
                                                educator. 
                                            </p>
                                            <p>Email: anujgurwara@gmail.com </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>  
        </div>
            
        <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};




export const query = graphql`
    query Master {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1-white" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home-white" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

Master.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
     
        
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default Master;



